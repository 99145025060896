/*
This component offers a modal with a login and signup form.
It also wraps the children components with the UserContextProvider.
The context provider has a function to open the modal with an optional custom message.
/* */
import {
  createContext, useContext, useState, useCallback,
} from 'react'
import dynamic from 'next/dynamic'

const Modal = dynamic(() => import('@csc/dls/Modal'))
const LoginWithExistingAccount = dynamic(() => import('@/components/Login/LoginWithExistingAccount'))
const SignUpForm = dynamic(() => import('@/components/Login/SignUpForm'))
const Text = dynamic(import('@csc/dls/Text'))

interface UserContextProps {
  openAuthModal: (message?: string) => void
}

const UserContext = createContext<UserContextProps | undefined>(undefined)

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider')
  }
  return context
}

const UserLoginSignUpWithContext: React.FC<{
  children: React.ReactNode,
  reloadCustomer: () => void
}> = ({ children, reloadCustomer }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoginView, setIsLoginView] = useState(true)
  const [modalMessage, setModalMessage] = useState<string | undefined>(undefined)
  const reloadCustomerAndCloseModal = () => {
    reloadCustomer()
    setIsModalOpen(false)
  }
  const openAuthModal = useCallback((message?: string) => {
    setModalMessage(message)
    setIsModalOpen(true)
  }, [])

  const closeModal = (type = 'reload') => {
    if (type && type === 'reload') { reloadCustomer() }
    setIsModalOpen(false)
    setModalMessage(undefined)
  }

  const toggleView = () => {
    setIsLoginView(!isLoginView)
  }

  return (
    <UserContext.Provider value={{ openAuthModal }}>
      {children}
      <Modal
        closeable
        open={isModalOpen}
        closeButton
        onClose={closeModal}
        className="min-w-fullWithPadding-4 md:min-w-600 relative"
      >
        {isLoginView ? (
          <LoginWithExistingAccount
            headingMessage="Log in to your account"
            secondMessage={modalMessage || 'Log in to access your account and enjoy exclusive benefits'}
            onSuccess={closeModal}
            successMessage="You have successfully logged in"
          />
        ) : (
          <SignUpForm
            reloadCustomer={reloadCustomerAndCloseModal}
            headingMessage="Create a new account"
          />
        )}
        <div className="w-full flex justify-center my-4">
          <Text bold size="md" className="mr-2">
            {isLoginView ? 'New to One Kings Lane?' : 'Already have an account?'}
          </Text>
          <button onClick={toggleView}>
            <Text bold size="md" link>
              {isLoginView ? 'Switch to Sign Up' : 'Switch to Log In'}
            </Text>
          </button>
        </div>
      </Modal>
    </UserContext.Provider>
  )
}

export default UserLoginSignUpWithContext
